import React from "react"
import { Listbox, Transition } from "@headlessui/react"
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/24/solid"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

// export function LineSelect({ label = "Unlabeled", options = [], value, onChange }) {
//   return (
//     <Listbox value={value} onChange={onChange}>
//       {({ open }) => (
//         <>
//           <Listbox.Label className="block text-sm font-medium text-gray-700">{label}</Listbox.Label>
//           <div className="relative mt-1">
//             <Listbox.Button className="relative cursor-default border-b-2 border-gray-300 py-2 pl-3
//             pr-10 text-left shadow-sm focus:border-indigo-500 sm:text-sm">
//               <span className="block truncate">{value.label}</span>
//               <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
//                 <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
//               </span>
//             </Listbox.Button>
//
//             <Transition
//               show={open}
//               as={React.Fragment}
//               leave="transition ease-in duration-100"
//               leaveFrom="opacity-100"
//               leaveTo="opacity-0"
//             >
//               <Listbox.Options className="absolute z-10 mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
//                 {options.map((option) => (
//                   <Listbox.Option
//                     key={option.id}
//                     className={({ active }) =>
//                       classNames(
//                         active ? 'text-white bg-indigo-600' : 'text-gray-900',
//                         'relative cursor-default select-none py-2 pl-3 pr-9'
//                       )
//                     }
//                     value={option}
//                   >
//                     {({ selected, active }) => (
//                       <>
//                         <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
//                           {option.label}
//                         </span>
//
//                         {selected ? (
//                           <span
//                             className={classNames(
//                               active ? 'text-white' : 'text-indigo-600',
//                               'absolute inset-y-0 right-0 flex items-center pr-4'
//                             )}
//                           >
//                             <CheckIcon className="h-5 w-5" aria-hidden="true" />
//                           </span>
//                         ) : null}
//                       </>
//                     )}
//                   </Listbox.Option>
//                 ))}
//               </Listbox.Options>
//             </Transition>
//           </div>
//         </>
//       )}
//     </Listbox>
//   )
// }

export function LineSelect({
  label = "Unlabeled",
  options = [],
  value = {},
  defaultValue = {},
  onChange,
  additionalClassName = "",
  additionalClassNameBtn = "",
}) {
  return (
    <Listbox
      className="relative mr-2"
      defaultValue={defaultValue}
      value={value}
      onChange={onChange}
    >
      {({ open }) => (
        <div className={`flex flex-col ${additionalClassName} `}>
          <div
            className="w-full  border-white border-4 bg-white overflow-hidden rounded-3xl lg:w-56
            sm:w-36 px-2 py-1/2 md:px-4"
          >
            <Listbox.Button
              className={`flex flex-row w-full flex-nowrap font-primary-bold justify-between items-center text-purplebglight-50 ${additionalClassNameBtn}`}
            >
              <span className="lg:text-base font-primary-bold md:text-base text-sm">
                {value.label || label}
              </span>
              <span className="">
                {open ? (
                  <ChevronUpIcon className="ml-2 h-3.5 w-3.5 sm:h-3.5 sm:w-3.5" />
                ) : (
                  <ChevronDownIcon className="ml-2 h-3.5 w-3.5 sm:h-3.5 sm:w-3.5" />
                )}
              </span>
            </Listbox.Button>
            <Transition
              show={open}
              as={React.Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className="absolute mt-3 left-0 right-0 ml-auto mr-auto  lg:left-[0] lg:right-[unset] rounded-3xl max-h-60 overflow-auto z-10  bg-white py-0 text-base
              shadow-lg w-56"
              >
                {options.map(option => (
                  <Listbox.Option
                    key={option.id}
                    className={({ active }) =>
                      classNames(
                        active
                          ? "text-white bg-purplebglight-50"
                          : "text-gray-900",
                        "relative cursor-default select-none py-2 flex items-center justify-center font-primary-bold"
                      )
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected
                              ? "font-primary-bold"
                              : "font-primary-book",
                            "block truncate "
                          )}
                        >
                          {option.label}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-purplebglight-50",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </div>
      )}
    </Listbox>
  )
}

// <Listbox.Label className={`${value.label ? "" : "invisible"} block text-sm font-medium
//           text-gray-700`}>{label}</Listbox
//           .Label>

import * as React from "react"
import { graphql, navigate } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Header } from "../components/typography"
import { Next, Prev } from "../components/buttons"
import { LineSelect } from "../components/inputs"
import GalleryLightbox from "../containers/gallery-lightbox"
import { GatsbyImage } from "gatsby-plugin-image"

const yearNow = new Date().getFullYear()
const start = 2020
const years = [...Array(yearNow - start + 1).keys()].map(x => ({
  id: x + start,
  label: x + start,
}))

const Events = ({ data, location, pageContext }) => {
  console.log(pageContext)
  const [openGallery, setOpenGallery] = React.useState()
  const clubs = data.allStrapiClub.edges.map(({ node }) => {
    return {
      id: node.id,
      label: node.clubName,
    }
  })

  if (
    !location.pathname.split("/").includes("page") &&
    typeof window !== `undefined`
  ) {
    window.location.pathname = "/gallery/page/1"
  }

  const handleOnClubChange = newValue => {
    setUntouchedClub(false)
    const clubPart = newValue.label === "All" ? "" : `club/${newValue.label}/`
    if (pageContext.year) {
      navigate(`/gallery/year/${pageContext.year}/${clubPart}page/1`)
    } else {
      navigate(`/gallery/${clubPart}page/1`)
    }
  }
  const handleOnYearChange = newValue => {
    setUntouchedYear(false)
    const yearPart = newValue.label === "All" ? "" : `year/${newValue.label}/`
    if (pageContext.club) {
      navigate(`/gallery/${yearPart}club/${pageContext.club}/page/1`)
    } else {
      navigate(`/gallery/${yearPart}page/1`)
    }
  }

  const nextPage = () => {
    if (pageContext.page + 1 > pageContext.maxPages) return
    if (pageContext.year && pageContext.club) {
      navigate(
        `/gallery/year/${pageContext.year}/club/${pageContext.club}/page/${
          pageContext.page + 1
        }`
      )
    } else if (pageContext.year && !pageContext.club) {
      navigate(`/gallery/year/${pageContext.year}/page/${pageContext.page + 1}`)
    } else if (!pageContext.year && pageContext.club) {
      navigate(`/gallery/club/${pageContext.club}/page/${pageContext.page + 1}`)
    } else {
      navigate(`/gallery/page/${pageContext.page + 1}`)
    }
  }
  const prevPage = () => {
    if (pageContext.page - 1 > 1) return
    if (pageContext.year && pageContext.club) {
      navigate(
        `/gallery/year/${pageContext.year}/club/${pageContext.club}/page/${
          pageContext.page + 1
        }`
      )
    } else if (pageContext.year && !pageContext.club) {
      navigate(`/gallery/year/${pageContext.year}/page/${pageContext.page - 1}`)
    } else if (!pageContext.year && pageContext.club) {
      navigate(`/gallery/club/${pageContext.club}/page/${pageContext.page - 1}`)
    } else {
      navigate(`/gallery/page/${pageContext.page - 1}`)
    }
  }

  const [untouchedClub, setUntouchedClub] = React.useState(true)
  const [untouchedYear, setUntouchedYear] = React.useState(true)

  const selectedClub = pageContext.club
    ? { value: pageContext.club, label: pageContext.club }
    : { value: "All", label: untouchedClub ? "Club" : "All" }
  const selectedYear = pageContext.year
    ? { value: pageContext.year, label: pageContext.year }
    : { value: "All", label: untouchedYear ? "Year" : "All" }

  return (
    <Layout
      location={location}
      title="Upcoming Events"
      ctaColor="bg-yellowdark-50"
      textCtaColor="text-fontpurple-50"
      fullHeader
    >
      <div className="bg-black">
        <Seo title="Gallery" />
        <div className="flex items-center">
          <Header additionalClassName="text-center mt-40  pl-8 lg:pl-0">
            Gallery
          </Header>
        </div>
        <div className="flex flex-row flex-nowrap pl-8 lg:pl-0 justify-start lg:justify-center mt-10 relative ">
          <LineSelect
            label="Club"
            options={[{ id: "All", label: "All" }, ...clubs]}
            value={selectedClub}
            onChange={handleOnClubChange}
            additionalClassName="md:mr-10 mr-2"
            additionalClassNameBtn="text-black"
          />
          <LineSelect
            label="Year"
            options={[{ id: "All", label: "All" }, ...years]}
            value={selectedYear}
            onChange={handleOnYearChange}
            additionalClassNameBtn="text-black"
          />
        </div>
        <div>
          {openGallery && (
            <GalleryLightbox
              imageSrcs={openGallery}
              onCloseCb={() => setOpenGallery(undefined)}
            />
          )}
          <ul
            className="mt-20 pb-20 space-y-4 sm:grid sm:grid-cols-2 sm:gap-6 sm:space-y-0 lg:grid-cols-3
            lg:gap-8
            max-w-7xl
             mx-auto px-8 "
          >
            {(pageContext.data || []).map(galleryItem => (
              <li key={galleryItem.id}>
                <div
                  className="space-y-6 xl:space-y-10 aspect-square relative cursor-pointer "
                  onClick={() =>
                    setOpenGallery(
                      galleryItem.images.map(image => image.localFile)
                    )
                  }
                >
                  <GatsbyImage
                    className="object-cover mx-auto h-full w-full rounded-3xl"
                    image={
                      galleryItem.images[0].localFile.childImageSharp
                        .gatsbyImageData
                    }
                    alt=""
                  />
                  <div
                    style={{ letterSpacing: "0.2px" }}
                    className={`font-semibold text-base text-white  transition-all duration-200  lg:text-xl pb-2 
                       font-primary-bold absolute bottom-5 left-8`}
                  >
                    {galleryItem.title}
                  </div>
                </div>
              </li>
            ))}
          </ul>
          <div className="w-full flex flex-row justify-center pb-20">
            {pageContext.page - 1 >= 1 && <Prev onClick={prevPage} white />}
            {pageContext.page + 1 <= pageContext.maxPages && (
              <Next white onClick={nextPage} />
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Events

export const pageQuery = graphql`
  query MyMineQuery {
    allStrapiClub(sort: { fields: events___dateTimeFrom, order: DESC }) {
      edges {
        node {
          id
          clubName
        }
      }
    }
  }
`
